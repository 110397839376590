import * as React from 'react';

import { renderRichText } from 'gatsby-source-contentful/rich-text';

import DisplayCard from '../DisplayCard';

import { cards, cards_title, cards_displayCards } from './cards-section.module.scss';

const CardsSection = ({data}) => {

    return (
        <section className={cards}>
            <div>
                <div className={cards_title}>
                    <span>{renderRichText(data.cardTitle)}</span>
                </div>
                <div className={cards_displayCards}>
                    {data.cards.map((card) => 
                        <div key={card.id}>
                            <DisplayCard data={card} />
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}

export default CardsSection