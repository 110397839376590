import * as React from 'react';

import { graphql, useStaticQuery } from "gatsby";

import CardsSection from '../components/CardsSection';
import TextImageSection from '../components/TextImageSection';
import ManagerContactForm from '../components/ManagerContactForm';
import Layout from '../components/Layout/layout';
import PageHero from '../components/PageHero';
import { Seo } from '../components/Seo/seo';

const StaffingPage = () => {

    const data = useStaticQuery(graphql`
    {
      allContentfulPageLayout(filter: {id: {eq: "6c796769-a2d0-58fc-95fd-feb4efa4b1e7"}}) {
        edges {
          node {
            name
            title
            content {
              ... on ContentfulPageHero {
                id
                name
                bannerImage {
                  gatsbyImageData(
                    quality: 100
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                  )
                }
                bannerText {
                  raw
                }
                smallBannerImage {
                  gatsbyImageData(
                    width: 990
                    height: 560
                    quality: 100
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                  )
                }
              }
              ... on ContentfulTextImageSection {
                id
                name
                title {
                  raw
                }
                bodyText {
                  bodyText
                }
                href
                linkText
                textLeft
                sectionImage {
                  gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FIXED)
                }
              }
              ... on ContentfulCardsSection {
                id
                name
                cardTitle {
                  raw
                }
                cards {
                  id  
                  name
                  title
                  cardIcon {
                    gatsbyImageData(width: 75, placeholder: BLURRED, layout: FIXED)
                  }
                  cardDescription {
                    raw
                  }
                }
              }
            }
            contactForm
          }
        }
      }
      allContentfulLocation {
        edges {
          node {
            phoneNumber
          }
        }
      }
    }
  `)
   //TODO: Need to pull phone number from CMS

  return (
    <Layout>
      <PageHero data={data.allContentfulPageLayout.edges[0].node.content[0]} />
      <TextImageSection data={data.allContentfulPageLayout.edges[0].node.content[1]} />
      <CardsSection data={data.allContentfulPageLayout.edges[0].node.content[2]} />
      <ManagerContactForm data={data.allContentfulLocation.edges[0].node.phoneNumber} />
    </Layout>
  )
}

export const Head = () => (
  <Seo 
    title="Partnering with The Good Life" 
    description="Hospitals and healthcare facilities that partner with TGL Med Staff will recieve top notch medical staff and the support of the TGL Med Staff family."
    pathname="/staffing" 
  />
  
)

export default StaffingPage