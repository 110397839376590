import * as React from 'react';

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import { card, cardWrapper, card_icon, cardText, card_title, 
    card_description, card_footnote} from './displayCard.module.scss';

const DisplayCard = ({data}) => {

    const icon = getImage(data.cardIcon)

    return (
        <div className={card}>
            <div className={cardWrapper}>
                {data.cardIcon &&       
                    <div className={card_icon}>
                        {data.cardIcon && 
                            <GatsbyImage image={icon} alt="" />
                        }
                    </div>
                }
                <div className={cardText}>
                    <div className={card_title}>
                        <h3>{data.title}</h3>
                    </div>
                    {data.cardDescription && 
                        <div className={card_description}>
                            <p>{renderRichText(data.cardDescription)}</p>
                        </div>
                    }
                    {data.cardFootNote && 
                        <div className={card_footnote}>
                            <p>{data.cardFootNote.cardFootNote}</p>
                        </div>
                    }
                </div>
            </div>
        </div>
    )

}

export default DisplayCard