import React, { useState } from "react";
import axios from "axios";
import { Script } from 'gatsby';

import { contactForm, contactForm_wrapper, contactForm_form, contactForm_formCta,
  contactForm_fields, contactForm_fields_first, contactForm_fields_last,
  contactForm_fields_email, contactForm_fields_phone, contactForm_fields_facility,
  contactForm_fields_comments, contactForm_fields_submit, contactForm_success,
  contactForm_cta, contactForm_ctaWrapper, contactForm_fields_required} from './manager-contact-form.module.scss';

const ManagerContactForm = ({data}) => {
    const [submitted, setSubmitted] = useState(false);
    const [notification, setNotification] = useState('')

    const [serverState, setServerState] = useState({
      submitting: false,
      status: null
    });

    const handleServerResponse = (ok, msg, form) => {
      setServerState({
        submitting: false,
        status: { ok, msg }
      });

      if (ok) {
        setSubmitted(true)
        form.reset();
      }
    };

    const handleOnSubmit = e => {
      e.preventDefault();
      const form = e.target;
      setServerState({ submitting: true });

      window.grecaptcha.ready(function() {
        window.grecaptcha.execute('6LdsOx4iAAAAAIsCht7EfyhaR5i_8hES02IoWyju', {
          action: 'submit'
        })
        .then(function(token) {
            // Add your logic to submit to your backend server here.
          document.getElementById('captchaResponse').value = token;
          
          axios({
            method: "post",
            url: "https://getform.io/f/2a1ad613-c8aa-449f-afdd-217dafd275d9",
            data: new FormData(form),
          })
          .then(r => {
              handleServerResponse(true, "Thanks!", form);
            })
            .catch(r => {
              handleServerResponse(false, r.response.data.error, form);
            });
        });
      });
    };


    return (
        <section className={contactForm}>
        <Script src="https://www.google.com/recaptcha/api.js?render=6LdsOx4iAAAAAIsCht7EfyhaR5i_8hES02IoWyju"></Script>
            <div className={contactForm_wrapper}>
                {!submitted ?
                    <div className={contactForm_form}>
                        <h3 className={contactForm_formCta}>Message a Manager</h3>
                        <form onSubmit={handleOnSubmit}>
                            <div className={contactForm_fields}>
                                <input type="text" name="firstName" placeholder="First Name*" className={contactForm_fields_first} required/>
                                <input type="text" name="lastName" placeholder="Last Name*" className={contactForm_fields_last} required/>
                                <input type="email" name="email" placeholder="Email*" className={contactForm_fields_email} required />
                                <input type="tel" name="phone" placeholder="Phone Number" className={contactForm_fields_phone} />
                                <input type="text" name="facility" placeholder="Facility" className={contactForm_fields_facility} />
                                <textarea name="comments" placeholder="Comments" rows="6" className={contactForm_fields_comments} />
                                <div className={contactForm_fields_required}>
                                    {notification && 
                                        <p>{notification}</p>
                                    }
                                </div>
                                <input type="hidden" id="captchaResponse" name="g-recaptcha-response"></input>
                                <input type="submit" value="submit"  
                                  data-sitekey="6LdsOx4iAAAAAIsCht7EfyhaR5i_8hES02IoWyju"
                                  data-callback='onSubmit' 
                                  data-action='submit'
                                  className={contactForm_fields_submit}/>
                            </div>
                        </form>

                    </div>
                    :
                    <div className={contactForm_success}>
                        <div>
                            <h3>Thank you!</h3>
                            <p>We'll be in touch soon.</p>
                        </div>
                    </div>
                }
                <div className={contactForm_cta}>
                    <div className={contactForm_ctaWrapper}>
                        <h2>Learn more about</h2>
                        <h6>TGL Med Staff</h6>
                        <h3>Call <a href={`tel:${data}`}>{ data.slice(1) }</a></h3>
                    </div>
                </div>
            </div>

        </section>

    )
}

export default ManagerContactForm